<template>
  <div id="classify-management">
    <AddClassify :addchangeMsg="addMsg"/>
    <BatchDelete :batchDeleteId="multipleSelection" :deleteMuch="deleteMuchMsg"/>
    <el-button type="info" icon="el-icon-s-tools" @click="reset">重置</el-button>
    <SearchClassify :inputButton="SearchMsg"/>
    <el-table :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="typeName" label="类别名称" width="180"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <DeleteClassify :index="tableData.length" :deleteChangeMsg="scope.row" :deleteOne="deleteOneMsg"/>
          <ModifyClassify :modifychangeMsg="scope.row" :modifyOne="modifyOneMsg"/>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :layout="layout"
      :total="total"
      :page-sizes="page_sizes"
      :page-size="page_size"
      :current-page="currentPage">
    </el-pagination>
  </div>
</template>

<script>
import {pages} from '../../mixins/pages';
import {findAllType, findTypeByNameLike} from '../../service/getData';
import AddClassify from '../../pages/SystemManagement/AddClassify.vue';
import DeleteClassify from '../../pages/SystemManagement/DeleteClassify.vue';
import ModifyClassify from '../../pages/SystemManagement/ModifyClassify.vue';
import BatchDelete from '../../pages/SystemManagement/BatchDelete.vue';
import SearchClassify from '../../pages/SystemManagement/SearchClassify.vue';
export default {
  name: 'ClassifyManagement',
  mixins: [pages],
  data() {
    return {
      multipleSelection: [], // 多选框哪几个被选中
      tableData: [], // 分页遍历数据
      input: ''//输入框
    };
  },
  components: {
    AddClassify,
    DeleteClassify,
    ModifyClassify,
    BatchDelete,
    SearchClassify
  },
  watch: {
    page_size_val(){
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    currentPage_val(){
      this._findAllType(this.currentPage_val, this.page_size_val);
    }
  },
  mounted(){
    // 第一次遍历信息
    this._findAllType();
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    addMsg(){//后台添加问题类别后重新遍历
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    deleteOneMsg(){//后台单个删除问题类别后重新遍历
      if (this.tableData.length===1){
        this._findAllType(this.currentPage_val-1, this.page_size_val);
      } else {
        this._findAllType(this.currentPage_val, this.page_size_val);
      }
    },
    deleteMuchMsg(){//后台批量删除问题类别后重新遍历
      console.log(this.tableData.length===this.multipleSelection.length);
      if (this.tableData.length===this.multipleSelection.length){
        this._findAllType(this.currentPage_val-1, this.page_size_val);
      } else {
        this._findAllType(this.currentPage_val, this.page_size_val);
      }
      this.multipleSelection = [];//讲批量选择清空
    },
    modifyOneMsg(){//后台修改问题类别
      this._findAllType(this.currentPage_val, this.page_size_val);
    },
    SearchMsg(values){//模糊搜索问题类别
      this.input = values;
      this._findAllType(1, this.page_size_val);
    },
    reset(){//重置
      this.input = '';
      this._findAllType(1, this.page_size_val);
    },
    _findAllType(pageNow, pageSize){//后台遍历不同问题类别，如果this.input有内容则模糊查询，如果没有内容则正常分页
      if (this.input.length===0){
        findAllType(pageNow, pageSize).then(values=>{
          if (values.code === 200){
            this.total = values.object.total;
            this.tableData = values.object.list;
          }
        });
      } else {
        findTypeByNameLike(this.input, pageNow, pageSize).then(values=>{
          if (values.code === 200){
            this.total = values.object.total;
            this.tableData = values.object.list;
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
