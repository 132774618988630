<template>
  <el-button type="danger" icon="el-icon-delete" @click="open">批量删除</el-button>
</template>

<script>
import {batchDeleteType} from '../../service/getData';
export default {
  name: 'BatchDelete',
  props: ['batchDeleteId', 'deleteMuch'],
  methods: {
    open(){
      if (this.batchDeleteId.length===0){
        this.$message({
          type: 'error',
          message: '请选择要删除的类别'
        });
      } else {
        this.$confirm(`确定批量删除${this.batchDeleteId.length}条数据吗?`, '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let typeId = '';
          this.batchDeleteId.map((item)=>{
            typeId+=`typeId=${item.typeId}&`;
          });
          batchDeleteType(typeId).then(values=>{
            if (values.code === 200){
              this.deleteMuch();
              this.$message({
                type: 'success',
                message: '删除成功'
              });
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    }
  }
};
</script>

<style>

</style>