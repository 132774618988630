<template>
    <el-button type="primary" icon="el-icon-plus" @click="open">添加</el-button>
</template>

<script>
import {addType} from '../../service/getData';
export default {
  name: 'AddClassify',
  props: ['addchangeMsg'],
  data(){
    return {
      val: ''
    };
  },
  methods: {
    open() {
      this.$prompt('请输入问题类别名称', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        addType(value).then(values=>{
          if (values.code === 200){
            this.addchangeMsg(values);//返回父组件信息
            this.$message({
              type: 'success',
              message: '添加成功'
            });
          } else {
            this.$message({
              type: 'error',
              message: values.message
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>