export const pages = {
  data() {
    return {
      total: 0, //一共多少条
      currentPage: 0, //当前第几页
      page_sizes: [5, 10, 20, 30], //每页多少条，可选择
      page_size: 0, //当前每页多少条
      page_size_val: 5, //获取当前每页多少条
      currentPage_val: 1, //获取当前是第几页
      layout: 'total, sizes, prev, pager, next, jumper'//完整配置
    };
  },
  methods: {
    handleSizeChange(val) {
      this.page_size_val = val;//当前每页多少条
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage_val = val;//当前第几页
      console.log(`当前页: ${val}`);
    }
  }
};